import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

import iconBackendSvg from '../images/icon_backend.svg'
import iconCloudSvg from '../images/icon_cloud.svg'
import iconFrontendSvg from '../images/icon_frontend.svg'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "office-photo1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      headerImageMobile: file(relativePath: { eq: "office_photo_mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      logo: file(relativePath: { eq: "logo/logo_white_trans.png" }) {
        childImageSharp {
          fixed(width: 360, height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      logoMobile: file(relativePath: { eq: "logo/logo_white_trans.png" }) {
        childImageSharp {
          fixed(width: 240, height: 54) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      logoIcon: file(relativePath: { eq: "logo/logo.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO/>

      <div style={{ position: 'relative', maxHeight: '360px', width: '100%' }}>
        <Img className='d-block d-sm-none' style={{ position: 'relative', zIndex: '-1' }}
          fluid={data.headerImageMobile.childImageSharp.fluid}/>
        <Img className='d-none d-sm-block' style={{ position: 'relative', minWidth: '900px', zIndex: '-1' }}
          fluid={data.headerImage.childImageSharp.fluid}/>

        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-12 mt-3'>
                <Img className='d-block d-sm-none' fixed={data.logoMobile.childImageSharp.fixed}/>
                <Img className='d-none d-sm-block' fixed={data.logo.childImageSharp.fixed}/>
              </div>
              <div className='col-12 mt-2'>
                <p style={{ color: 'white' }}>福岡で Web システムの受託開発を中心としている会社です。</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section style={{ backgroundColor: 'white' }} className='pt-3 mb-4'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h2>専門分野<span className='d-none d-sm-inline-block ui-span-in-heading'>Focusing</span></h2>
            </div>
          </div>

          <div className='row'>
            <ul className='list-unstyled col-12'>
              <li className='d-flex flex-column flex-sm-row'>
                <div className='mb-2 mr-sm-2'>
                  <img src={iconFrontendSvg} width='90' height='90' alt='frontend icon'/>
                </div>
                <div className='mb-2 mt-sm-1'>
                  <h3 className='mb-1'>フロントエンド</h3>
                  <p>React</p>
                </div>
              </li>

              <li className='d-flex flex-column flex-sm-row'>
                <div className='mb-2 mr-sm-2'>
                  <img src={iconBackendSvg} width='90' height='90' alt='backend icon'/>
                </div>
                <div className='mb-2 mt-sm-1'>
                  <h3 className='mb-1'>バックエンド</h3>
                  <p>Rails もしくはサーバーレス（Firebase & Node.js）</p>
                </div>
              </li>

              <li className='d-flex flex-column flex-sm-row'>
                <div className='mb-2 mr-sm-2'>
                  <img src={iconCloudSvg} width='90' height='90' alt='cloud icon'/>
                </div>
                <div className='mt-sm-1'>
                  <h3 className='mb-1'>クラウド</h3>
                  <p>Google Cloud もしくは AWS</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className='mt-3 mb-4'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h2>大切にしていること<span className='d-none d-sm-inline-block ui-span-in-heading'>The four principles</span></h2>
            </div>
          </div>

          <div className='row'>
            <h3 className='col-12'>福岡を中心に活動する</h3>
            <p className='col-12'>
              福岡を拠点として開発を行い、メンバーもここに集まります。例えば将来、拠点を東京に移すということはありません。
            </p>

            <h3 className='col-12 mt-3'>ディベロッパーである</h3>
            <p className='col-12'>
              受託開発、プロダクト開発は内部で行い、外注を中心としません。
            </p>

            <h3 className='col-12 mt-3'>独立した資本</h3>
            <p className='col-12'>
              組織の独立性を大切にするため、外部から資本を調達しません。
            </p>

            <h3 className='col-12 mt-3'>チームが採用の権利をもつ</h3>
            <p className='col-12'>
              人事組織によってのみ採用が決められるのではなく、一緒に働く仲間を自分たちで選ぶ権利をもちます。
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='border rounded'>
                <div className='row'>
                  <div className='col-12 col-sm-4 col-lg-3 text-center '
                    style={{ paddingTop: '26px', paddingRight: '16px' }}>
                    <Img className='float-sm-right' fixed={data.logoIcon.childImageSharp.fixed}/>
                  </div>
                  <div className='col-12 px-2 py-3 col-sm-8 col-lg-9' id='about'>
                    <h3 className='mb-1'>社名</h3>
                    <p>
                      株式会社メメントモリ
                    </p>

                    <h3 className='mb-1 mt-3 mt-sm-1'>代表者</h3>
                    <p>
                      小宮 孝介
                    </p>

                    <h3 className='mb-1 mt-3 mt-sm-1'>設立</h3>
                    <p>
                      2016年12月
                    </p>

                    <h3 className='mb-1 mt-3 mt-sm-1'>本社所在地</h3>
                    <p>
                      福岡県福岡市内
                    </p>

                    <h3 className='mb-1 mt-3 mt-sm-1'>お問い合わせ</h3>
                    <p>
                      <a href='mailto:contact@mementocorp.com'>contact@mementocorp.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
